<template>
  <SoloBaseSelect
    :searchMethod="searchMethod"
    :keymap="keymap"
    :modelValue="modelValue"
    @update:modelValue="updateEmit"
  />
</template>
<script>
import SoloBaseSelect from '@/components/selectors/solo-selector'
import { searchxgbTag } from '@/domains/base-xgb'

/**
 * 单选远程搜索框用例 XGB搜选股票 多用于页面搜索头部
 * @param vModel string number object 若string、number为真需要手动指定options
 *
 * @param name 更改搜索组件名称
 * @param searchMethod 编写搜索函数
 * @param keymap 指定keymap
 */

function searchMethod(query, ctx) {
  if (!/\S/.test(query)) return
  ctx.loading.value = true
  const params = Object.assign({ keyword: query })
  searchxgbTag(params)
    .then((res) => {
      if (res.Tags && res.Tags.length) {
        ctx.options.value = res.Tags
        return
      }
    })
    .finally(() => {
      ctx.loading.value = false
    })
}

export default {
  name: 'TagSelect',
  components: { SoloBaseSelect },
  props: {
    modelValue: { type: [String, Number, Object], default: '' }
  },
  data() {
    return {
      keymap: {
        label: 'Name',
        value: 'Id'
      }
    }
  },
  methods: {
    searchMethod,
    updateEmit(ev) {
      this.$emit('update:modelValue', ev)
    }
  }
}
</script>