<template>
  <div class="page-common">
    <div class="page-params">
      <el-button class="param-item" type="primary" icon="el-icon-plus" @click="handleAddTag">新增分类</el-button>
      <el-button class="param-item" icon="el-icon-sort" @click="sortDialogVisible = true">调整顺序</el-button>
      <el-button class="param-item" @click="updateCategory" :loading="submiting" type="primary">提交</el-button>
    </div>
    <el-dialog
      custom-class="medium-modal"
      :close-on-click-modal="false"
      title="调整顺序"
      v-model="sortDialogVisible"
    >
      <Draggable v-model="tagList" item-key="id">
        <template #item="{element}">
          <el-tag class="drag-tag">{{element.Id}} {{element?.Tag.Name}}</el-tag>
        </template>
      </Draggable>
      <div style="height: 30px"></div>
    </el-dialog>
    <el-dialog
      title="编辑分类"
      custom-class="medium-modal"
      :close-on-click-modal="false"
      v-model="editDialogVisible"
    >
      <el-form :model="editingTag" label-width="100px">
        <el-form-item label="标签">
          <XgbTagSelect style="width: 100%" v-model="editingTag.Tag" />
        </el-form-item>
        <el-form-item label="图片">
          <UploadQiniu v-model="editingTag.Icon" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="submitFormItem">提 交</el-button>
      </template>
    </el-dialog>
    <div class="tag-items">
      <div v-for="(item) in tagList" :key="item.sortId" class="tag-item">
        <h2 v-if="item.Tag">{{item.Tag.Name}}</h2>
        <el-button size="small" @click="handleEditTag(item)">编辑</el-button>
        <el-button size="small" type="danger" @click="handDeleteTag(item.sortId)">删除</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v1'
import Draggable from 'vuedraggable'
import UploadQiniu from '@/components/base/upload-qiniu'
import XgbTagSelect from '@/components/use-case/xgb-tag-select'
import { fetchRecommend, updateRecommend } from '@/domains/base-xgb/xgb-app-conf'

export default {
  components: {
    Draggable,
    UploadQiniu,
    XgbTagSelect
  },
  data() {
    return {
      tagList: [],
      submiting: false,
      editingTag: {
        Background: '',
        Icon: '',
        Tag: {
          Id: '',
          Name: ''
        }
      },
      sortDialogVisible: false,
      editDialogVisible: false
    }
  },
  mounted() {
    this.initTagList()
  },
  methods: {
    initTagList() {
      fetchRecommend().then((res) => {
        if (res.AllCatgsV2 && res.AllCatgsV2.length) {
          this.tagList = res.AllCatgsV2.map((category) => ({
            ...category,
            sortId: uuid()
          }))
        }
      })
    },
    handleAddTag() {
      this.editingTag = {
        Background: '',
        Icon: '',
        Tag: {
          Id: '',
          Name: ''
        }
      }
      this.editDialogVisible = true
    },
    handleEditTag(tag) {
      this.editingTag = tag
      this.editDialogVisible = true
    },
    handDeleteTag(sortId) {
      this.tagList = this.tagList.filter((item) => item.sortId !== sortId)
    },
    submitFormItem() {
      const { sortId } = this.editingTag
      if (sortId) {
        this.tagList = this.tagList.map((item) => (item.sortId === sortId ? this.editingTag : item))
      } else {
        this.tagList.unshift({
          sortId: uuid(),
          ...this.editingTag
        })
      }
      this.editDialogVisible = false
      this.updateCategory()
    },
    updateCategory() {
      this.submiting = true
      updateRecommend({
        AllCategoriesV2: this.tagList.map((item) => ({
          ...item,
          TagId: item.Tag.Id
        }))
      })
        .then(() => {
          this.$message.success('提交成功！')
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
        .finally(() => {
          this.submiting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-items {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  .tag-item {
    background: #f4f4f4;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 12px;
    h2 {
      font-weight: 600;
      margin-bottom: 12px;
    }
    img {
      display: block;
      height: auto;
      width: 200px;
    }
  }
}
</style>
